.container {
    width: 100vw;
    height: 100vh;

    overflow: hidden;
}

.sider-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
    height: 30px;

    box-sizing: border-box;
    
    margin: 20px 0;
    padding-left: 20px;

    color: #FFF;
    font: normal 20px Consolas;
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;

    letter-spacing: 2px;
    word-spacing: 10px;
    font: normal 30px "等线 Light";
}